@import "../../../../../scss/custom.scss";

.balloon-group {
    position: relative;
    color: $linde-gray;
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    top: 2px;
}

.balloon-ring {
    position: absolute;
    border-radius: 21px;
    border: dashed 2px $linde-gray;
    width: 40px;
    height: 40px;
}

i.balloon:nth-of-type(even){
    bottom: 5px;
}

i.balloon:nth-of-type(odd){
    top: 5px;
}

i.balloon:nth-of-type(1){
    left: 2.5px;
}

i.balloon:nth-of-type(3){
    right: 2.5px;
}

i.balloon {
    position: relative;
}

