/* The following block can be included in a custom.scss */

// Override variables

// Colors
$primary: #005591;
$blue: $primary;
$linde-gray: #f1f5f7;   
$body-bg: #f1f5f7;

// Compact overrides 
$form-label-margin-bottom: .25rem;
$list-group-item-padding-y: .25rem; // Override style to follow precedent set in prototype
$list-group-item-padding-x: .25rem; // Override style to follow precedent set in prototype

// Update theme options
$theme-colors: () !default;
$theme-colors: map-merge((
  "primary":    $primary,
  "linde-gray": $linde-gray
), $theme-colors);

// Switch input
.form-switch .form-check-input {
    width: 1.75rem;
    height: 1rem;
}

//Override tooltip styling
.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #ffffff;
    text-align: center;
    background-color: $primary;
    border-radius: 0.25rem;
}

.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    // border-top-color: $primary;
}


.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23fff'/></svg>") !important;
}

// Breadcrumb
.breadcrumb {
    background-color: transparent !important;
    .breadcrumb-item a {
        color: $primary;
    }
}

@import "~admin-lte/build/scss/adminlte.scss";
/* import bootstrap to set changes */
// @import "~bootstrap/scss/bootstrap";

// Button size xsm


.card.card-tabs .card-tools, .card .card-tools {
  margin: 0.3rem 0.5rem;
}

// .card-tools, .card-body {
//     background-color: $linde-gray;
// }
.overflow-hide {
    white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:150px;
}

.small-label {
  font-size: 12px;
}

.disabled-cursor{
  cursor: not-allowed;
}
