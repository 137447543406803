.layout-component {
    min-height: 100vh;
    padding: 0;
    display: flex;
    flex-direction: column;
}

/* .content-outlet {
    height: 100%;
    overflow: auto;
} */
