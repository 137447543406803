/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

@import "../scss/custom";

.create-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

.table-spinner {
  height: 1em;
  width: 1em;
}

.creation-loading {
  opacity: .3;
  transition: opacity 0.5s;
  pointer-events: none;
}

a {
  cursor: pointer;
}

.wrapper .content-wrapper {
  max-height: calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px));
}

.card-tabs .nav.nav-tabs {
  border-color: white;

  .nav-link.active {
    background-color: white;
    border-color: white;
    margin-bottom: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .balloon-ring {
    animation: balloon-ring-spin infinite 20s linear;
  }
}


@keyframes balloon-ring-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn.btn-xsm,
btn-group-xsm>.btn {
  padding: 2px;
  height: 20px;
  width: 20px;
  line-height: 1;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  align-content: center;

  // i {
  //     font-size: 10px;
  // }
}

.modal-header .btn-close {
  margin: 0 !important;
}

.custom-table th:hover {
  background-color: #e6e6e6;
}

.checkbox-field-custom {
  position: relative !important;
  left: 15px !important;
}

.he3-edit-tabular {
  .form-select, .form-select-sm {
    padding-top: .12rem;
    padding-bottom:0px;
  }
  .form-control, .form-control-sm, .form-select, .form-select-sm {
    font-size: 12px !important;
    height: 24px !important;
    min-height: 24px !important;
  }
  .form-control:focus, .form-control-sm:focus, .form-select:focus, .form-select-sm:focus {
    box-shadow: none !important;
  }

  .react-date-picker.form-control {
    padding-top: .12rem;
  }
  .form-control.readonly {
    background-color: #fff !important;
  }
  .saved-row {
    .form-control, .form-control-sm, .form-select, .form-select-sm {
      border-color: white !important; 
    }
    .form-control:focus, .form-control-sm:focus, .form-select:focus, .form-select-sm:focus {
      border-color: #129dff !important;
    }
    .form-control.is-invalid, .form-control-sm.is-invalid, .form-select.is-invalid, .form-select-sm.is-invalid {
      border-color: #dc3545 !important
    }
    
  }
  
}

.x-small-input{
  font-size: 12px !important;
  height: 24px !important;
  min-height: 24px !important;
}




/* 
.he3-edittable {
  .form-control, .form-control-sm, .form-select, .form-select-sm {
    font-size: 12px !important;
    height: 26px !important;
    min-height: 26px !important;
    border: none !important; 
  }
} */

/* 
.dropdown-menu.show {
  z-index: 9999 !important;
} */