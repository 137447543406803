.linde-date-picker.form-control-sm {
  padding-right: 2px;
  min-width: 160px;
}

.linde-date-picker .react-date-picker__wrapper {
  border: 0;
}

.linde-date-picker .react-date-picker__calendar{
  z-index: 2;
}

.linde-date-picker .react-date-picker__inputGroup__leadingZero{
  margin-bottom: 1em;
}

.linde-date-picker.disabled {
  background-color: #e9ecef;
  opacity: 1;
}