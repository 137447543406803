@import "../../../../../../scss/custom.scss";

.custom-table {
  margin-bottom: 0;
  // table-layout: fixed;


  tbody {
    border-top: 0 !important;
  }


  thead tr {
    th {
      position: sticky;
      top: 0;
      background-color: white;
      // border-bottom: 2px solid black !important;
      // border-collapse: separate !important;
      border-top: none !important;
      border-bottom: none !important;
      box-shadow: inset 0 -2px 0 #000000;
      background-clip: padding-box;
      border: 0;
      z-index: 1;
    }
  }

  tr td {
    // cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.asset-table {
    td, th {
      max-width: 150px;
    }
  }
}



.expandable-table {
  // tbody tr:nth-of-type(2n+1) {
  //     background-color: unset;
  //   }

  > tbody {
    i {
      display: inline-block;
      transition: transform 200ms;
      transform: rotate(0deg);

      &.expanded-chevron {
        transform: rotate(90deg);
      }
    }

  > tr:nth-of-type(4n + 1) {
      background-color: $table-accent-bg  !important;

      td {
        background-color: $table-accent-bg  !important;
      }
    }

  }

}