.accordion-button {
    padding: 0rem;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
}

.roles-accordion .custom-table thead tr {
    th {
        background-color: white;
    }
}

.roles-accordion .custom-table tr {

    th:first-child,
    td:first-child {
        padding-left: 1.5em;
    }

    th:last-child,
    td:last-child {
        padding-right: 1.5em;
    }

}
