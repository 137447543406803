.dropdown {

    .dropdown-toggle.btn {
        width: 100%;
        text-align: end;
    }

    .dropdown-menu.show {
        width: 100%;
        text-align: end;
    }

}

