.error-template {
  display: flex;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 32px 0px 16px 0px;

  .error-template-headline {
    font-size: 150px;
    font-weight: 300;
    white-space: nowrap;
  }
  
  .error-template-content > *{
    font-weight: 300;
    width: 450px;
  }
}

