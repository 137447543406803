.maintenance-request-list {
    // border-top: 1em solid black;
    box-shadow: inset 0 -2px 0 #000000, inset 0 2px 0 #000000;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
}