
.line-items-list-container {
    // z-index: 9999;
    position: relative;
}

.work-order-line-items-section {
    // box-shadow: inset 0 -2px 0 #000000;
    // margin-top: 0.3rem;
    // margin-left: 0.5rem;
    // margin-right: 0.5rem;

    .expand-icon {

        transform: rotate(0deg);
        transition: transform 200ms;
        display: inline-block;
        
        &.expanded-chevron {
            transform: rotate(90deg);
            
        }
    }

    
}