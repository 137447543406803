.nav-sidebar .nav-treeview {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;

    &.menu-is-open {
        // Need to update this to be adjustable based on the number of items in menu 
        max-height: 250px;
    }
}

.maintenance-submenu {
    &.menu-is-open {
        // Need to update this to be adjustable based on the number of items in menu 
        max-height: 320px !important;
    }
}

.profile-active {
    .profile-wrapper {
        background-color: #005591;
        &:hover {
            background-color: #005591;
        }
        .info {
            color: white;
        }
    }
}

.profile-wrapper {
    display: flex;
    flex-grow: 1;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 4px;
    border-bottom: 1px solid #4f5962;
    border-radius: 4px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1)
    }

}

[class*=sidebar-dark] .user-panel {
    border-bottom: 0px solid #4f5962;
}