.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #ffffff;
    text-align: center;
    background-color: #005591;
    border-radius: 0.25rem;
}

.basic-input-group {

    &.input-group .btn {
        z-index: 0;
    }

    .conversion-info-button.btn-outline-secondary:hover {
        color: unset;
    }

    
}

// fieldset:disabled {
//     .basic-input-group {
//         .btn {
//             pointer-events: unset;
//             cursor: pointer;
//         }
//     }
// }

