@import "../../../../../scss/custom";
.nav-header {
    height: 80px;
    display: flex;
}

.breadcrumb-item {
    a {
        color: white;
        text-decoration: none;
    }
}

.custom-navbar{
  .rbt-aux {
    position: absolute;
    top: 0.4em;
    right: 0.6em;
    color: $primary;
}
  .form-control {
    opacity: 0.2;

  }
  .form-control:focus{
    opacity: 1.0;
  }
}

.global-search-nav-input input::placeholder {
  color: black;
}